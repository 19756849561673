@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.scroll-disable{
  overflow-y: hidden;
  overflow-x: hidden;
}

.Home {
  min-height: 1000px;
}

.header .navlink:hover{
  background-color: #f9fafb;
  font-weight: 600;
}

.Footer {
   position: absolute;
   bottom: 0px;
   margin: auto;
   left: 0px;
   right: 0px;
   border-width: 0;
   border-top: 1px solid;
   border-image:linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 33%, rgba(226,226,226,1) 33%, rgba(227,227,227,1) 66%, rgba(255,255,255,1) 66%, rgba(255,255,255,1) 100%) 10;
 }

.Footer a:hover {
  /* color:rgba(59, 130, 246); */
  text-decoration: underline;
}

 .header .navlink.active{
   background-color: #f3f4f6;
   color:  #111827;
 }

 .About .resume a:hover {
    /* border-color: rgb(54, 54, 54); */
    background-color: rgb(229 231 235);
    font-weight: 600;
 }

 .mythought .title:hover {
  text-decoration: underline;
  cursor: pointer;
  
 }

 .Casestudycard:hover {
   box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
   cursor: pointer;
 }

 .blog a{
   text-decoration:underline;
 }

 button:focus {
  border: none;
  outline: none;
}

.menuicon{
  z-index: 60;
}

 @media (max-width: 639px) {

   .navg{
     display: flex !important;
     flex-direction: column;
     justify-content: flex-start;
     background-color: white;
     position: fixed;
     top: 0px;
     padding-top: 132px;
     z-index: 50;
     bottom: 0;
     left: 0;
     right: 0;
     transform: translateX(20px);
     opacity: 0;
     pointer-events: none;
     transition: all 0.3s ease;
   }

   .navg.visible{
    transform: translateX(0px);
    opacity: 1;
    pointer-events: all;
   }
 }


  .About img {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(0); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(0); /* Microsoft Edge and Firefox 35+ */
} 